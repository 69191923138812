import { CustomerStatus, PaymentMethodType, RideRequestStatus, SystemTripCancellationReasonType, TripCancellationTriggerType, TripLocationSourceType, TripLocationType, TripStatus, UserRole, UserTripCancellationReasonType, VehicleType } from '../enums';

import { IErrorDto, IFilterOptions, IGenericItemLinkResponse, IGenericListMetaData, ILanguageResponseModel, IPageInfo } from '.';

export interface ITripsListResponseModel {
    data: {
        metaData: IGenericListMetaData[];
        pageInfo: IPageInfo;
        list: ITripsListItemResponseModel[];
        filterColumns: IFilterOptions[]
    };
    errors: IErrorDto[];
    hasErrors: boolean;
}

export interface ITripsListItemResponseModel {
    canceledBy? : string | null;
    date?: number;
    createdTime?: number;
    pickupTime?: number | null;
    dropoffTime?: number | null;
    city?: string | null; 
    bookingId?: IGenericItemLinkResponse;
    customer?: IGenericItemLinkResponse;
    captain?: IGenericItemLinkResponse;
    statusDate?: number;
}

export interface IRiderListItemResponseModel {
    userId?: IGenericItemLinkResponse;
    name?: string;
    phoneNumber?: string;
    email?: string;
    status?: CustomerStatus;
}

export interface ITripsListItemResponseModelGenericListPaginatedResponseEnvelope {
    data?: ITripsListItemResponseModelGenericListPaginatedResponse;
    errors?: IErrorDto[] | null;
    hasErrors?: boolean;
}

export interface ITripsListItemResponseModelGenericListPaginatedResponse {
    metaData?: IGenericListMetaData[] | null;
    pageInfo?: IPageInfo;
    list?: ITripsListItemResponseModel[] | null;
    filterColumns?: IFilterOptions[] | null;
}

export interface IRiderListItemResponseModelGenericListPaginatedResponseEnvelope {
    data?: IRiderListItemResponseModelGenericListPaginatedResponse;
    errors?: IErrorDto[] | null;
    hasErrors?: boolean;
}

export interface IRiderListItemResponseModelGenericListPaginatedResponse {
    metaData?: IGenericListMetaData[] | null;
    pageInfo?: IPageInfo;
    list?: IRiderListItemResponseModel[] | null;
    filterColumns?: IFilterOptions[] | null;
}

export interface ITripsDetailsResponseModel {
    statusHistory?: ITripsDetailsHistoryResponseServiceModel[] | null;
    driver?: ITripUserResponseModel;   
    orderingUser?: ITripUserReadServiceModel;
    rideType?: string | null;
    vehicleType?: VehicleType;
    dropOff?: ITripLocationResponseModel;
    origin?: ITripLocationResponseModel;
    destination?: ITripLocationResponseModel;
    userPaymentMethod?: IUserPaymentMethodResponseModel;
    tripEarnings?: ITripEarningsResponseModel;
    tripExpense?: ITripExpenseResponseModel;
    promoCode?: IRewardResponseModel;
    distance?: number | null;
    duration?: string | null;
    rideAcceptCaptainLocation?: any | null;
    rideCancelCaptainLocation?: any | null;
    cancellation?: ITripCancellationResponseModel;
    captainArriveDuration?: number | null;
    captainArriveDistance?: number | null;
    originZone?: string | null;
    destinationZone?: string | null;
    rideRequests?: IRideRequestsResponseModel[];
    RiderWallet?: number | null;
    tripStatusChangHistory?: ITripStatusChangHistoryResponseServiceModel[] | null;
    estimatedCost?: ITripEstimationResponseModel;
    mappedCaptain?: string; 
}


export interface IRideRequestsResponseModel {
    status?: RideRequestStatus | null;
    recipientExternalId?: string;
}


export interface ITripsDetailsHistoryResponseServiceModel {
    status?: TripStatus;
    externalId?: string;
    created?: number;
    updated?: number | null;
}

export interface ITripUserResponseModel {
    avatarUrl?: string | null;
    name?: string | null;
    rating?: number;
    spokenLanguages?: ILanguageResponseModel[] | null;
    phoneNumber?: string | null;
    externalId?: string;
    created?: number;
    updated?: number | null;
}

export interface ITripUserReadServiceModel {
    avatarUrl?: string | null;
    name?: string | null;
    rating?: number;
    spokenLanguages?: ILanguageReadServiceModel[] | null;
    phoneNumber?: string | null;
    externalId?: string;
    id?: number;
    created?: number;
    updated?: number | null;
}

export interface ILanguageReadServiceModel {
    englishName?: string | null;
    isoCode?: string | null;
    externalId?: string;
    id?: number;
    created?: number;
    updated?: number | null;
}

export interface ITripLocationResponseModel {
    arrived?: number | null;
    displayName?: string | null;
    sourceType?: TripLocationSourceType;
    tripLocationType?: TripLocationType;
    city?: string | null;
    latitude?: number;
    longitude?: number;
    name?: string | null;
    externalId?: string;
    created?: number;
    updated?: number | null;
}

export interface IUserPaymentMethodResponseModel {
    instrumentId?: string | null;
    type?: PaymentMethodType;
    isDisabled?: boolean;
    scheme?: string | null;
    last4Digits?: string | null;
    isLastUsed?: boolean;
    externalId?: string;
    created?: number;
    updated?: number | null;
}

export interface ITripEarningsResponseModel {
    total?: IAmountResponseModel;
    tripFare?: IAmountResponseModel;
    waitTime?: IAmountResponseModel;
    farawayBonus?: IAmountResponseModel;
    commission?: IAmountResponseModel;
    tolls?: IAmountResponseModel;
    cancellationFee?: IAmountResponseModel;
    rate?: number;
    captainProfit: number;
    net: number;
}

export interface IAmountResponseModel {
    amount?: number;
    currencyCode?: string | null;
}

export interface ITripExpenseResponseModel {
    total?: IAmountResponseModel;
    tripFare?: IAmountResponseModel;
    waitTime?: IAmountResponseModel;
    tolls?: IAmountResponseModel;
    cancellationFee?: IAmountResponseModel;
    tripMultiplier?: number;
    waitTimeFare: IAmountResponseModel;
}

export interface ITripEstimationResponseModel {
    total?: IAmountResponseModel;
    tripFare?: IAmountResponseModel;
    captainProfit?: IAmountResponseModel;
    commission?: IAmountResponseModel;
    waitTime?: IAmountResponseModel;
    tolls?: IAmountResponseModel;
    cancellationFee?: IAmountResponseModel;
    waitTimeFare?: IAmountResponseModel;
    promoCodeDiscount?: IAmountResponseModel;
}

export interface IRiderPaymentListItemResponseModel {
    externalId?: string;
    date?: number;
    type?: PaymentMethodType;
    amount?: IAmountResponseModel;
    tripId?: IGenericItemLinkResponse;
}

export interface IRiderPaymentListResponseModel {
    data: {
        metaData: IGenericListMetaData[];
        pageInfo: IPageInfo;
        list: IRiderPaymentListItemResponseModel[];
        filterColumns: IFilterOptions[]
    };
    errors: IErrorDto[];
    hasErrors: boolean;
}

export interface ITripCancellationResponseModel {
    otherReason?: string | null;
    systemReasonType?: SystemTripCancellationReasonType;
    triggerType?: TripCancellationTriggerType;
    userReasonType?: UserTripCancellationReasonType;
    cancellingUser?: IUserResponseModel;
    reason?: ITripCancellationReasonResponseModel;
    externalId?: string;
    created?: number;
    updated?: number | null;
    cancellationPaymentStatus?:string;
}

export interface IUserResponseModel {
    name?: string | null;
    externalId?: string;
    created?: number;
    updated?: number | null;
}

export interface ITripCancellationReasonResponseModel {
    allowCustomReason?: boolean;
    intendedFor?: UserRole;
    name?: string | null;
    order?: number;
    externalId?: string;
    created?: number;
    updated?: number | null;
}

export interface IRewardResponseModel {
    externalId?: string;
    name?: string | null;
    description?: string | null;
    code?: string | null;
    discount?: number;
    discountType?: DiscountType;
    paymentMethod?: PaymentMethodType;
    rideType?: string | null;
}

export enum DiscountType {
    Money = 'Money',
    Percentage = 'Percentage',
}

// new mdoel for trip status change history
export interface ITripStatusChangHistoryResponseServiceModel {
    tripFlag?: TripStatus;
    externalId?: string;
    created?: number;
    updated?: number | null;
    name?: string | null;
    reason?: string | null;
    tripFlagString?: string | null;
    userExternalId?: string;
}
